<template>

	<layout :include_header="true">

		<template v-slot:header-center>

			<main_nav />

		</template>

		<template v-slot:header-end>

			<support_link />

		</template>

		<template v-slot:main-body>

			<div class="w-100 h-100 position-relative overflow-hidden">

				<div class="w-100 h-100 d-flex">

					<div class="d-flex flex-column w-100 h-100">

						<team_nav label="Team members" />

						<div class="w-100 h-100 overflow-auto">
						
							<div class="container h-100 py-4">

								<div class="row h-100 justify-content-center">

									<div class="col-12 col-md-10 col-lg-6">

										<loader v-if="loading" />

										<div v-else class="card mb-3">						

											<div v-for="(member, member_key) in members" :class="( member_key > 0 ? 'border-top' : '' )" class="card-body d-flex justify-content-between align-items-center p-4" :key="'member-' + member.id">

												<div>
													<p class="h5 mb-0">{{ member.name }}</p>
													<p class="form-text mb-0">{{ member.email }}</p>
												</div>

												<div v-if="auth_user.id != member.id && auth_user.type == 'admin'">
													<btn_submit 
														v-if="member.is_invited && !member.is_accepted"
														label="Resend invite" 
														icon="email"
														btn_class="btn btn-outline-primary"
														:icon_before="true"
														:loading="( verify_loading == member.email )" 
														@click.prevent="resend_verify( member.email )"
													/>

													<form_control
														v-else
														group_class="mb-0"
														type="select"
														name="type"
														:options="store_permissions"
														:required="true"
														:error="error"
														v-model="member.type"
														v-on:update:modelValue="submit( member )"
													/>
												</div>

												<span v-else-if="auth_user.id == member.id">You</span>

											</div>

										</div>

									</div>

								</div>

							</div>

						</div>

					</div>

				</div>

			</div>

		</template>

	</layout>

</template>

<script>
import { mapGetters } from 'vuex';
import layout from '@/components/layout'
import main_nav from '@/components/main_nav'
import team_nav from '@/components/team_nav'
import btn_submit from '@/components/btn_submit'
import support_link from '@/components/support_link'
import loader from '@/components/loader'
import form_control from '@/components/form_control'
import user_service from '@/services/user_service'
import moment from 'moment'

export default {
	name: 'team.members',

	components: {
		layout,
		loader,
		main_nav,
		team_nav,
		btn_submit,
		support_link,
		form_control,
	},

	data() {
		return {
			loading: false,
			verify_loading: false,
			type_loading: false,
			members: null,
			error: {},
			success: false,
			moment: moment
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user'] ),

		...mapGetters( 'permissions', ['store_permissions'] ),

	},

	mounted()
	{
		this.index()
	},

	methods: {
		reset_error()
		{
			this.error = {}

			this.success = null
		},

		async index()
		{
			this.loading = true 

			this.reset_error()

			user_service.index().then(( response ) => {
				this.members = response.data
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},

		async resend_verify( email )
		{
			this.verify_loading = email 

			this.reset_error()

			user_service.resend_verify({
				email: email
			}).then(() => {
				this.success = true
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.verify_loading = false
			})
		},

		async submit( user )
		{
			this.type_loading = true 

			this.reset_error()

			user_service.update( user.id, user ).then(() => {
				this.success = true
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.type_loading = false
			})
		},
	}
}
</script>

<style>

</style>
